import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import styles from './index.module.css';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div className={styles.sidebar}>
      <div className={styles.profileImage}>
        <Img fluid={data.profileImage.childImageSharp.fluid} />
      </div>

      <h1 className={styles.heading}>Hugh Middleton</h1>

      <ul className={styles.socialIcons}>
        <li>
          <a href="https://twitter.com/hughmiddleton" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-twitter" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="https://au.linkedin.com/in/hughfmiddleton" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-linkedin" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="https://github.com/hughfm" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-github" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="mailto:hughfmiddleton@gmail.com" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-envelope" aria-hidden="true" />
          </a>
        </li>
      </ul>
    </div>
    <div className={styles.mainContent}>
      <p>Hi, I&apos;m Hugh. I&apos;m a software engineer from Melbourne, Australia.</p>
      <p>I work for <a href="//www.canva.com">Canva</a>.</p>
      <p>
        I love building for the web - for work and sometimes little things just for fun like this <a href="https://waveform.hughfm.com">audio player</a>.
      </p>
      <p>
        In the past I&apos;ve worked for&nbsp;
        <a href="https://www.forgeglobal.com" target="_blank" rel="noopener noreferrer">Forge</a>,&nbsp;
        <a href="https://www.redbubble.com" target="_blank" rel="noopener noreferrer">Redbubble</a>
        &nbsp;and&nbsp;
        <a href="https://www.mountmary.com.au" target="_blank" rel="noopener noreferrer">Mount Mary</a>.
      </p>
      <p>
        From time to time, I write about music &amp; teaching on my <a href="https://blog.hughfm.com">blog</a>.
      </p>
      <p>
        Please feel free to <a href="mailto:hughfmiddleton@gmail.com" target="_blank" rel="noopener noreferrer">get in touch</a>.
      </p>
    </div>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query {
    profileImage: file(relativePath: { eq: "profile-image-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
